@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50%{
    transform: translateY(8%);
  }
  65%{
    transform: translateY(6%);
  }
  80%{
    transform: translateY(4%);
  }
  95%{
    transform: translateY(2%);
  }     
  100% {
    transform: translateY(0%);
  }   
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }
  50%{
    -webkit-transform: translateY(8%);
  }
  65%{
    -webkit-transform: translateY(6%);
  }
  80%{
    -webkit-transform: translateY(4%);
  }
  95%{
    -webkit-transform: translateY(2%);
  }     
  100% {
    -webkit-transform: translateY(0%);
  } 
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

.ng-hide-add, .ng-hide-remove {
    /* ensure visibility during the transition */
    display: block !important; /* yes, important */
}

.fade-out.ng-hide {
  opacity: 0;
}

.fade-out.ng-hide-remove,
.fade-out.ng-hide-add {
  display: block !important;
}

.fade-out.ng-hide-remove {
  transition: all linear 200ms;
}

.fade-out.ng-hide-add {
  transition: all linear 200ms;
}

.ng-hide-add, .ng-hide-remove {
    /* ensure visibility during the transition */
    display: block !important; /* yes, important */
}

.cssFade {
    transition: 0.3s linear all;
    opacity: 1;
}
.cssFade.ng-hide {
    opacity: 0;
}