body {
    font-family: $font-default;
    font-size: $font-default-size;
    background-color: #f5f8fa;
}

a {
    cursor: pointer;
}

p {
    margin: 20px 0 10px;
}

h1 {
    font-family: $font-h1;
    text-decoration: none;
    color: $font-default-color;

    small {
        margin-left: 1em;
    }
}

h3 {
    font-family: $font-default;
    text-decoration: none;
}

pre {
    clear: both;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.bindings__content {
    margin-top: 0;
}

.bindings__switch {
    outline: none;
    margin-left: 15px;
}

.form-button__group {
    @extend .btn-group;

    @extend .btn-group-justified;

    p {
        margin-top: 10px;
        font-size: 12px;
        font-style: italic;
    }
}

.form-button__group button {
    width: 100px !important;
}

.form-button__button--selected {
    @extend .btn;

    @extend .btn-primary;

    @include animate(background-color, 1s, ease-out);

    &:hover {
        background-color: rgb(103, 189, 236);
    }
}

.form-button__button--default {
    @extend .btn;

    @include animate(background-color, 1s, ease-out);

    background-color: #FFF;
    border: 1px solid #CCC;

    &:hover {
        background-color: rgb(242, 121, 34);
    }
}

.bindings__container {
    clear: both;
}

.footer-support__container {
    height: 200px;
}