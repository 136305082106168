.header {
    @extend .row;
    margin: 0;
    background: white;
}

.header__topbar {
    @extend .topbar !optional;

    @extend .container-fluid;

    background-color: $white;
    height: 108px;
    width: 1170px;
    padding: 0;
}

.header__topbar__logo {
    @extend .col-md-4;
    height: 100%;
    position: relative;

    .header__topbar__logo--img {
        width: 268px;
        height: 40px;
        position: absolute;
        left: 0;
        bottom: 18px;
    }
}

.header__topbar__advert {
    @extend .col-md-8;
    height: 100%;
    position: relative;

    .header__topbar__advert--img {
        width: 728px;
        height: 90px;
        position: absolute;
        right: 0;
        bottom: 10px;
    }
}
