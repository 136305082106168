@mixin section-spacing($space) {
    height: 100%;
    padding-top: $space;
    text-align: center;
}

@mixin animate($property, $timing, $transition) {
  -webkit-transition: $property $timing $transition;
  -ms-transition: $property $timing $transition;
  -moz-transition: $property $timing $transition;
  -o-transition: $property $timing $transition;
  transition: $property $timing $transition;
}

// Control border radius.
@mixin radius($radius:$global-radius) {
  @if $radius {
    @if $vendor-prefix {
      -webkit-border-radius: $radius;
    }
    border-radius: $radius;
  }
}

@mixin round-corners($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

// Side Radius

@mixin side-radius($side, $radius) {
  @if $side == left {
    @if $vendor-prefix {
      -moz-border-radius-bottomleft: $radius;
      -moz-border-radius-topleft: $radius;
      -webkit-border-bottom-left-radius: $radius;
      -webkit-border-top-left-radius: $radius;
    }
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
  }
  @else if $side == right {
    @if $vendor-prefix {
      -moz-border-radius-topright: $radius;
      -moz-border-radius-bottomright: $radius;
      -webkit-border-top-right-radius: $radius;
      -webkit-border-bottom-right-radius: $radius;
    }
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
  @else if $side == top {
    @if $vendor-prefix {
      -moz-border-radius-topright: $radius;
      -moz-border-radius-topleft: $radius;
      -webkit-border-top-right-radius: $radius;
      -webkit-border-top-left-radius: $radius;
    }
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
  }
  @else if $side == bottom {
    @if $vendor-prefix {
      -moz-border-radius-bottomright: $radius;
      -moz-border-radius-bottomleft: $radius;
      -webkit-border-bottom-right-radius: $radius;
      -webkit-border-bottom-left-radius: $radius;
    }
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

$tablet-width: 768px;
$desktop-width: 992px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-and-desktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}