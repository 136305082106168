/* slide in from the bottom */
@keyframes slideInDown {
  from  { transform:translateY(15%); }
  to    { transform: translateY(0); }
}
@-moz-keyframes slideInDown {
  from  { -moz-transform:translateY(15%); }
  to    { -moz-transform: translateY(0); }
}
@-webkit-keyframes slideInDown {
  from  { -webkit-transform:translateY(15%); }
  to    { -webkit-transform: translateY(0); }
}

.helper-dialog__container.ng-hide-remove     { 
    -webkit-animation:slideInDown 0.5s both ease-in;
  -moz-animation:slideInDown 0.5s both ease-in;
  animation:slideInDown 0.5s both ease-in;  
}

.invisible-hit-area.noanimate {

  .helper-dialog__container.ng-hide-remove     { 
   animation: none; 
   transition: none; 
}

}

.helper-dialog__container {
  @extend .cssFade;
  position: absolute;
  z-index: 299;
  width: 314px;
  left: 50px;
  border:1px solid  #DDDDDD;
  background-color: #fff;   
  padding: 5px 20px 5px 40px;
  .helper-dialog__content {
    img {
        vertical-align: middle;
        float: left;
        margin-left: -32px;
        margin-top: -6px;
    }
    h1 {
    font-size: 14px;
    font-family:'Open Sans'!important;
    font-style: normal;
    font-weight: 700;
    color: #5E5E5E;
    }

    p {
    font-size: 14px;
    font-family: $font-default;
    color: #5E5E5E;
    }
  
  }
}