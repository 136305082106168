/*doc
---
title: Buttons
name: button
category: Base CSS
---

Button styles can be applied to any element. Typically you'll want to
use either a `<button>` or an `<a>` element:

```html_example
<button class="btn btnDefault">Click</button>
<a class="btn btnDefault" href="http://trulia.com">Don't click</a>
```

If your button is actually a link to another page, please use the
`<a>` element, while if your button performs an action, such as submitting
a form or triggering some javascript event, then use a `<button>` element.

##Button Sizes
There are three 3 sizes for buttons: Large, medium (default)
and small. Simply apply the size modifier class for the desired size.
There is also an additional modifier that will make the button take the
full width of the container. It may be used with the any of the button
size and style modifiers.

Button                                                            | Modifier Class
----------------------------------------------------------------- | -----------------
<button class="btn btnDefault btnLrg">Large</button>              | `btn btnDefault btnLrg`
<button class="btn btnDefault">Default</button>                   | `btn btnDefault`
<button class="btn btnDefault btnSml">Small</button>              | `btn btnDefault btnSml`
<button class="btn btnDefault btnFullWidth">Full width</button>   | `btn btnDefault btnFullWidth`

*/

.development-console {
    margin: 30px 0 0 0;
    float: left;
    @extend .col-md-12;
    max-width: 1170px; 
    padding-left: 0;
    padding-right: 0;

    div {
        padding: 5px;
    }

}

.actionConsole {
    background:none;
    color:#08c;
    padding:0;
    border-radius:0;
    border:none;
}

.no-border {
    border-top: none !important;
}

.test {
    background: #fff;
}

.policy-select__cp__switch--toggle
  .switch-light.switch-candy {
    > span {
      background-color: #fff;
      margin-left: 14px;
    }
}

.policy-select__cp {
    background-color: #f5f8fa;
    float: left;
    // height: 347px;
    @extend .col-md-12;
	background-color: #f5f8fa;
    .policy-select__cp__switch {
        padding: 22px 0;
        @extend .col-md-4;
        .policy-select__cp__switch--label {
            float: left;
            color: #5e5e5e;
            padding: 5px 0;
        }
        .policy-select__cp__switch--toggle {
            float: left;
             padding: 0;
             @extend .col-md-6;

        }

    }
    .policy-select__cp__historic {
        float: left;
        clear: both;
        padding: 0;
        max-height: 500px;
        // transition: max-height 0.25s ease-in;

        @extend .col-md-12;
        .policy-select__cp__historic--label {
            float: left;
            clear: both;
            padding: 0 0 20px 0;
            font-family: "Open Sans";
            color: #183D71;
            font-size: 16px;
            font-weight: 700;
        }
        .policy-select__cp__historic--select-options {
            float: left;
            clear: both;
            padding: 20px;
            margin-bottom: 6px;
            background-color: #FFF;
            @extend .col-md-12;
            select {
                margin-right: 20px;
            }      
        }

        .policy-select__cp__historic__selected-details {
            float: left;
            clear: both;
            margin-top: 10px;
            padding: 15px 0;
            background-color: #FFF;
            @extend .col-md-12;
        }

    }
    .policy-select__cp__current {
        float: left;
        clear: both;
        padding: 0;
        // position: absolute;
        @extend .col-md-12;  
        .policy-select__cp__current--label {
            float: left;
            clear: both;
            font-family: "Open Sans";
            color: #183D71;
            font-size: 16px;
            font-weight: bold;
            padding: 20px 0;            
        }      
        .policy-select__cp__historic--label {
            float: left;
            clear: both;
            font-family: "Open Sans";
            color: #183D71;
            font-size: 16px;
            font-weight: bold;
            padding: 20px 0;
        }  

    }

    padding-left: 0;
    padding-right: 0;
    border: none;

}

.policy-select__rankings {
    float: left;
    width: 100%;
}

.policy-select__table {
    font-size: 14px;
    padding: 0 15px 20px 15px;

    td input {
        max-width: 100px;
    }

    & > thead > tr > th {
        border-bottom: 1px solid #ddd;
        color: #183D71;
    }
}

.sub-row td {
    border-top: none !important;
}

.table > thead > tr > th {
    vertical-align: bottom;
    // border-bottom: 2px solid #ddd;
    border-bottom: none;
}

.table-hover > tbody > tr:hover {
    background-color: rgb(233, 241, 223);
}

.table-hover > tbody > tr.sub-table:hover {
    background-color: rgb(255,255,255);
}

.provider-logo {
    max-width: 120px;
}

.provider-logo-alt {
    max-width: 90px;
}

// @-webkit-keyframes fadeInOut {
//     0% {
//         opacity: 0;
//     }
//     16% {
//         opacity: 1;
//     }
//     84% {
//         opacity: 1;
//     }
//     100% {
//         opacity: 0;
//     }
// }
// @keyframes fadeInOut {
//     0% {
//         opacity: 0;
//     }
//     16% {
//         opacity: 1;
//     }
//     84% {
//         opacity: 1;
//     }
//     100% {
//         opacity: 0;
//     }
// }

// @-webkit-keyframes fadeIt {
//   0%   { background-color: #FFFFFF; }
//   50%  { background-color: #AD301B; }
//   100% { background-color: #FFFFFF; }
// }
// @-moz-keyframes fadeIt {
//   0%   { background-color: #FFFFFF; }
//   50%  { background-color: #AD301B; }
//   100% { background-color: #FFFFFF; }
// }
// @-o-keyframes fadeIt {
//   0%   { background-color: #FFFFFF; }
//   50%  { background-color: #AD301B; }
//   100% { background-color: #FFFFFF; }
// }
@keyframes fadeInOut {
    border: 2px solid #284e97;
  0%   { background-color: rgb(255,255,255); }
  50%  { background-color: #d9edf7;}
  100% { background-color: rgb(255,255,255); }
}

.el-break {
    height: 30px;
}

.moved-policy {

    background-color: rgb(255,255,255); 
    -webkit-animation: fadeInOut 6s;
    animation: fadeInOut 6s;
}

.table-sub {
    border: 1px solid #DDD;
    height: 100px;
    table {
        width: 100%;
    }
}

.table > tbody + tbody {
    border-top: 3px solid #f5f8fa;
}

.additional-policy-link, .sub-row td a {
    cursor: pointer; cursor: hand;
}

.main-row {
  height: 123px;
  border-bottom: 1px solid #DDD;
  input {
    width: 75px;
  }
  td {
    // width: 100px;
    color: rgb(94, 94, 94);
    padding-top: 35px !important;
  }
}

.sub-row {
  td {
    width: 100px;
    color: rgb(94, 94, 94);
    text-align: center;
    padding-top: 34px;
  }
  input {
    width: 75px;
  }
}

.main-row td.provider, .sub-row td.provider {
  padding-top: 16px !important;
  text-align: center;
}
.sub-row td.provider {
    padding-top: 32px !important;
    padding-left: 15px;
    padding-right: 25px;
}

.main-row td.rank, .sub-row td.rank {
  width: 50px;
}

.main-row td.ranking-score, .sub-row td.ranking-score {
  color: #147ec2;
  font-size: 16px;
  font-weight: bold;
}

.current-policy-table-header {
    color: #183d71;
}

.historic-policy-table-header {
    color: #183d71;
    th.blank-col {
        width: 82px;
    }
}

.pdf-icon {
    // max-width: 40px;
    color: rgb(24, 61, 113);
    cursor: pointer;
}

.pdf-icon-sub {
    // max-width: 40px;
}

.input-sm {
    max-width: 200px;
}

.select-button, .rankings-button {
    padding: 6px 10px;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    font-family: Open Sans;
    font-size: 12px;
    color: #5e5e5e;
    font-weight: bold;
    text-align: center;
    border-radius:0;
    box-shadow: none;
}

.select-button {
    width: 82px;
    height: 29px;
    text-transform: uppercase;
}

.sub-row td.docs {
    width: 50px;
}

.sub-row td.rankings-select, .rankings-select {
    // padding-top: 15px;
    padding-top: 34px;
    width: 100px;
    text-align: center;
    vertical-align: baseline;
}

.show-rankings-link {
    margin: 16px 0 16px 0;
}


.policy-select__rankings--table {

.btn-default {
        border: 1px solid #dddddd;
        background-color: #ffffff;
        font-family: Open Sans;
        font-size: 12px;
        color: #5e5e5e;
        font-weight: bold;
        text-align: center;
    #{$all-states} {
        border: 1px solid #dddddd;
        background-color: #ffffff;
        font-family: Open Sans;
        font-size: 12px;
        color: #5e5e5e;
        font-weight: bold;
        text-align: center;
    }
}

.btn-disabled {
    opacity: 0.25;
    background-color: lightgrey;
}
// .btn-success.focus
.btn-success {
            color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c;
    #{$all-states} {
        color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c;
    }
}

}

.historic-main-row {
    input {
        width: 75px;
    }

    .btn-success {
        border-color: rgb(88, 185, 88);
        background-color: rgb(255, 255, 255);
        color: rgb(88, 185, 88);
        text-transform: uppercase;
        font-weight: bold;
        padding: 6px 10px;
        font-size: 12px;
    }

}

.historic-main-row--table {
    width: 100%;
}

.table {
    // margin-bottom: -2px;
    margin-bottom: 20px
}

.policy-select__cp__current {

}

.policy-select__rankings--container {
    @extend .col-xs-12;
    @extend .panel ;
    @extend .panel-default;
    padding-left: 0;
    padding-right: 0;
    border: none;
    background: transparent;
    float: left;
    width: 100%;

    .policy-select__rankings--table {
        @extend .panel-body; 
        @extend .table-responsive;
        padding: 15px 0;
        width: 100%;
        background-color: $white;
            float: left;
    }
}

.policy-select__rankings--loading {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.4);
    z-index: 999;
}

.animate-show-hide.ng-hide {
  opacity: 0;
}

.animate-show-hide.ng-hide-add,
.animate-show-hide.ng-hide-remove {
  transition: all linear 0.5s;
}

.cssSlideUp {
    transition: .5s linear all;
    height: 100px;
    overflow: hidden;
}
.cssSlideUp.ng-hide {
    height:0;
}

.cssSlideUp2 {
    transition: .5s linear all;
    height: 155px;
    overflow: hidden;
}
.cssSlideUp2.ng-hide {
    // height:0;
    opacity: 0;
}

.ng-hide-add, .ng-hide-remove {
    /* ensure visibility during the transition */
    display: block !important; /* yes, important */
}

.cssFade {
    transition: .5s linear all;
    opacity: 1;
    // height: 163px;
}
.cssFade.ng-hide {
    opacity: 0;
}

.policy-select__cp__historic__list {
    // position: absolute;
    background-color: $white;
    width: 100%;
    margin-top: 10px;
    // height: 155px;
    max-height: 155px;
    float: left;
    transition: .15s linear all;
    overflow: hidden;
}

.policy-select__cp__historic__providers {
    position:relative;
}

.policy-select__historic__policies-wrapper {
    position:relative;
}

.policy-select__cp__historic__selected-details {
    position: absolute;
}

.policy-select__historic__policy-list {
    // position: absolute;
    float: left;
    top: 114px;
    left: 0;
    width: 100%;
    // height: 300px;
    // background-color: lightblue;
    background-color: $white;
}

.policy-select__historic__policy-details {
    // position: absolute;
    float: left;
    // top: 114px;
    // left: 0;
    width: 100%;
    // height: 300px;
    // background-color: orange;
    background-color: $white;
    // z-index: 999;
}
.btn-danger.policy-select {
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    border-radius: 0;
    padding: 6px 10px;
    border-color: rgb(221, 221, 221);
    background-color: rgb(96, 183, 96);
    font-size: 12px;
}

.policy-select__rankings--panel-selection {
    text-align: right;
    margin-bottom: 0;
    float: left;
    width: 100%;
    .policy-select__rankings--panel-selection__title {
        float: left;
        width: 100%;
        border-radius: 5px 12px 0px 0px;
        background-color: rgb(235, 235, 235);
        width: 213px;
        text-align: left;
    h2 {
        font-size: 15px;
        color:#183d71;
        font-weight: bold;
        padding-left: 10px;
        padding-bottom: 4px;
        margin-top: 10px;
        }
    }
    .policy-select__rankings--panel-selection__select {
        float: right;
    }
    button, &:active, &:focus, &:visited {
        border: none;
        box-shadow: none;
        border-radius: 0;
        font-size: 13px;
        color: #737373;
        .caret {
            margin-left: 20px;
        }
    }
    .dropdown-menu > li > a {
        font-size: 13px;
        color: #737373;
    }
}

.additional-policy-link {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
    .icon-angle-right {
        font-size: 18px;
        font-weight: normal;
        margin-right: 3px;
        -webkit-transition:all 300ms ease-in 0s;
        -moz-transition: all 300ms ease-in 0s;
        -o-transition: all 300ms ease-in 0s;
        transition: all 300ms ease-in 0s;
        &.active {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }
}

.policy-name {
    width: 99px;
    height: 24px;
    background-image: linear-gradient(90deg, #ffcd03 1%, #ffdb4b 100% );
    background-position: center center;
    text-align: center;
    a, a:hover {
        text-decoration: none;
    }
    p {
        width: 93px;
        height: 19px;
        padding: 2px 0 2px 8px;
        text-transform: uppercase;
        font-family: Open Sans;
        font-size: 13px;
        color: #147ec2;
        margin: 0;
    }
}

.additional-policy:hover {
    background-color: #e9f1df;
}


