/* Global Vars */

$font-default: "Open Sans";
$font-default-color: #000;
$font-default-size: 14px;

$font-h1:'Varela Round', sans-serif;

/* Brand Colors */

$cie-blue: #1E80C0;
$cie-menu-blue: #155FA2;

/* Colors */

$white: #FFFFFF;
$black: #000000;
$lightest-grey: #F5F5F5;
$light-grey: #ccc;
$dark-grey: #666666;

$curious-blue: #3599db;
$jaffa: #f28f44;

// Use vendor prefix on CSS3 attributes
$vendor-prefix: true !default;

/* Progress Tracker */
$not-completed: #8f8fb0;

/* Form Selection and rollover colours */

$selected: #428bca;
$notSelectedRollover: #6ec7f3;
$lightBlueBgRollover: #cadff0;

/* Green button */

$buttonGreenFontColour: #fff;
$buttonGreen: #60B760;
$buttonGreenRolloverFontColour: #fff; //#313131;
$buttonGreenRollover: #2E9922;

/* Blue button */

$buttonBlueFontColour: #fff;
$buttonBlue: #428bca;
$buttonBlueRolloverFontColour: #fff;
$buttonBlueRollover: #6ec7f3;

/* White/Blue button */

$buttonWhiteFontColour: #428bca;
$buttonWhite: #fff;
$buttonWhiteRolloverFontColour: #fff;
$buttonWhiteRollover: #6ec7f3;

/* Footer band */

$footer-support: #284e97; //was #155FA2 
$footer-links: #1E80C0;

// Links

$all-states: '&:active, &:hover, &:visited';
$active-hover-state: 'a:active, a:hover';

// Colours

$malachite: #0baf0f;