@charset "UTF-8";

@font-face {
    font-family: "ciexpert";
  src:url("../fonts/fontastic/ciexpert.eot");
  src:url("../fonts/fontastic/ciexpert.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fontastic/ciexpert.woff") format("woff"),
    url("../fonts/fontastic/ciexpert.ttf") format("truetype"),
    url("../fonts/fontastic/ciexpert.svg#ciexpert") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "ciexpert" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "ciexpert" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%icongen {
  font-family: "ciexpert" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.icon {
    font-size: 20px;
    line-height: 24px;
    float: left;
    // margin-left: 10px;
}

.icon.active {
    color: $white;
    + span {
        color: $white;
    }
}

.icon-chevron-down:before {
  content: "\61";
}
.icon-chevron-right:before {
  content: "\62";
}
.icon-chevron-left:before {
  content: "\63";
}
.icon-chevron-up:before {
  content: "\64";
}
.icon-additional-payment:before {
  content: "\66";
}
.icon-angle-up:before {
  content: "\6a";
}
.icon-angle-right:before {
  content: "\6b";
}
.icon-angle-down:before {
  content: "\6c";
}
.icon-arrow-circle-right:before {
  content: "\6d";
}
.icon-file-pdf-o:before {
  content: "\6e";
}
.icon-category-closed:before {
  content: "\6f";
}
.icon-category-open:before {
  content: "\70";
}
.icon-info:before {
  content: "\71";
}
.icon-plus-square-o:before {
  content: "\72";
}
.icon-beneficial-condition:before {
  content: "\73";
}
.icon-phone:before {
  content: "\75";
}
.icon-twitter:before {
  content: "\76";
}
.icon-linkedin:before {
  content: "\77";
}
.icon-download:before {
  content: "\78";
}
.icon-phone-square:before {
  content: "\74";
}
.icon-mail:before {
  content: "\79";
}
.icon-plus:before {
  content: "\65";
}
.icon-square:before {
  content: "\7a";
}
.icon-client:before {
  content: "\41";
}
.icon-female:before {
  content: "\42";
}
.icon-male:before {
  content: "\43";
}
.icon-number-none:before {
  content: "\48";
}
.icon-number-0:before {
  content: "\49";
}
.icon-number-1:before {
  content: "\4a";
}
.icon-number-2:before {
  content: "\4b";
}
.icon-number-3:before {
  content: "\4c";
}
.icon-policy-a:before {
  content: "\4d";
}
.icon-policy-b:before {
  content: "\4e";
}
.icon-enhanced-payment:before {
  content: "\44";
}
.icon-check:before {
  content: "\67";
}
.icon-arrow-circle-left:before {
  content: "\68";
}
.icon-thumbs-o-up:before {
  content: "\69";
}
.icon-pencil:before {
  content: "\45";
}
.icon-accept:before {
  content: "\46";
}
.icon-edit:before {
  content: "\47";
}
.icon-pencil-edit:before {
  content: "\4f";
}
.icon-home:before {
  content: "\50";
}
.icon-home-1:before {
  content: "\51";
}
.icon-pram:before {
  content: "\52";
}
.icon-minus-square-o:before {
  content: "\53";
}
.icon-minus:before {
  content: "\54";
}
.icon-plus-1:before {
  content: "\55";
}
.icon-minus-1:before {
  content: "\56";
}
.icon-right:before {
  content: "\57";
}
