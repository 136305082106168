* {
  box-sizing: border-box;
}

.sticky-policy-bar,
.assessment__container {
  position: relative; 
}
.sticky-policy-bar {
  background: #fff;
  height: 80px;
  z-index: 150;
  margin-bottom: -80px;
  // box-shadow: 0 2px 3px rgba(0,0,0,.4);
}

.sticky-policy-bar-scrolled {
  position: fixed;
  width: 100%;
  top: 0;
}

.sticky-footer-navbar {
  position: fixed;
  width: 100%;
  height: 48px;
  bottom: 0;
}

// .progress-tracker__container--details {
//   position: fixed;
//   width: 100%;
//   top: 152px;
// }