/*doc
---
title: Background Colors
name: background
category: Base CSS
---

We have a few background colors that can be used in various contexts.
These are not for use as the entire page background but instead for
specific components and modules on the page.

<div class="line txtC">
    <div class="col cols6 ">
      <div class="docSwatch backgroundLowlight"></div>
      <code>backgroundLowlight</code>
    </div>
    <div class="col cols6 ">
      <div class="docSwatch backgroundHighlight"></div>
      <code>backgroundHighlight</code>
    </div>
    <div class="col cols6 ">
      <div class="docSwatch backgroundBasic"></div>
      <code>backgroundBasic</code>
    </div>
    <div class="col cols6 lastCol">
      <div class="docSwatch backgroundInverse"></div>
      <code>backgroundInverse</code>
    </dit;v>
</div>
*/

#trainingCreditsHelper {
    display: block !important;
}

.client-details {
    font-size: 14px;
}

.client-details__credits {
    @extend .col-md-12;

    padding: 0;
    margin-bottom: 20px;
    display: table;
}

.client-details__credits--question {
    @extend .col-md-6;

    padding-left: 0;
    padding-right: 30px;
    float: none;
    display: table-cell;

    p {
        margin-top: 0;
    }
}

.client-details__credits--answer {
    @extend .col-md-6;

    float: none;
    display: table-cell;
    top: 8px;
    padding: 0 0 0 15px;
}

.client-details__credits--available {
    @extend .col-md-6;

    float: right;
}

.client-details__credits--highlight {
    color: $black;
}

.client-details__group--personal {
    clear: both;
    padding: 0;
    margin-left: -20px;
    margin-right: -20px;

    &:hover {
        background-color: $lightBlueBgRollover;
    }

    @include animate(background-color, 0.2s, ease-in-out);
}

.groupDefaultBg {
    background-color: #fff !important;

    &:hover {
        background-color: #fff !important;
    }

    @include animate(background-color, 0.2s, ease-in-out);
}

.client-details__group {
    clear: both;
    padding: 20px 10px;
}

.client-details__group--personal__row {
    @extend .form-group;

    @extend .row;

    clear: both;
    padding: 10px 0;
    margin-right: 0;
    margin-left: 0;

    .client-details__form--dob {
        @extend .col-sm-6;

        padding: 0;
    }

    .client-details__form__input {
        padding-right: 20px;
    }

    .client-details__form__label {
        padding-left: 20px;
    }
}

.client-details__form__row {
    @extend .form-group;

    @extend .row;

    clear: both;
    padding: 10px 0;
    margin-right: 0;

    label.client-details__form__label {
        padding: 10px 15px;
        margin-top: 0;
    }

    .client-details__form__input {}
}

.client-details__form__dob__container {
    @extend .col-md-6;

    padding-right: 20px;
}

.client-details__group--smoker-status {
    clear: both;

    .client-details__form__label {
        margin-top: 0;
    }
}

.client-details__group--sum-assured {
    .client-details__form__row {
        margin-bottom: 0;
        margin-left: -20px;
    margin-right: -20px;
    .client-details__form__label {
padding-left: 20px;
    }
.client-details__form__input {
        padding-right: 20px;
}
.help-block {
    padding-left: 20px;
}
        // .client-details__form__label {
        //     padding-left: 0;
        // }
    }
}

.client-details__form__btn-group {
    @extend .btn-group;

    width: 100%;
}

.client-details__form__checkbox-button--left {
    @extend .btn;

    @include side-radius(left, 4px);

    width: 50%;
    border: 1px solid #ccc;

    &:hover {
        color: #FFF;
        background-color: $notSelectedRollover;
    }

    @include animate(background-color, 0.2s, ease-in-out);
}

.client-details__form__checkbox-button--right {
    @extend .btn;

    @include side-radius(right, 4px);

    width: 50%;
    border: 1px solid #ccc;

    &:hover {
        color: #FFF;
        background-color: $notSelectedRollover;
    }

    &:active, &:visited {
        color: #FFF;
        background-color: $selected;
    }

    @include animate(background-color, 0.2s, ease-in-out);
}

.client-details__group--childrens-cover {
    .client-details__form__label {
        padding-left: 0;
        margin-top: 2px;
    }
}

.active.client-details__form__checkbox-button--left, .active.client-details__form__checkbox-button--right {
    color: #FFF;
    background-color: $selected;
}

.client-details__next {
    padding-left: 0;
    float: right;

    a {
        color: $buttonGreenFontColour;
        background-color: $buttonGreen;

        @include radius(4px);

        &:hover {
            color: $buttonGreenRolloverFontColour;
            background-color: $buttonGreenRollover;

            @include animate(background-color, 0.2s, ease-in-out);
        }
    }
}

.client-details--inline-label {
    float: left;
    line-height: 35px;
    vertical-align: middle;
    font-weight: normal;
    text-align: center;
}

.client-details__form__label {
    @extend .col-sm-6;

    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin-top: 10px;
}

.client-details__form__input {
    @extend .col-sm-6;

    font-size: 14px;
    font-weight: normal;
    line-height: 35px;
}

.client-details__group--smoker-status, .client-details__group--childrens-cover {
    .client-details__form__input {
        padding-left: 20px;
        padding-right: 0;
    }
}

.client-details__group--childrens-cover__has-children, .client-details__group--childrens-cover__no-children {
    padding-left: 20px;
    border-left: 10px solid #a2c6eb;

    div {
        padding-left: 3px;
    }
}

.client-details__group--childrens-cover__no-children, .client-details__group--childrens-cover__has-children {
    .client-details__form__row {
        .client-details__form__input {
            padding-left: 0;
        }
    }
}

.client-details__group--sum-assured {
    .client-details__form__input {
        padding-left: 12px;
        padding-right: 0;
    }
}

.client-details__summary {
    font-size: 14px;

    .client-details__summary__data {
        margin-left: 1em;
        color: #428bca;
    }

    span {
        margin-left: 1em;
    }
}

// .policy-select__table {
//     font-size: 14px;
//     margin: 0 15px 20px 15px;

//     td input {
//         max-width: 100px;
//     }

//     & > thead > tr > th {
//         border-bottom: 1px solid #ddd;
//     }
// }

.invisible-hit-area {
    position: absolute;
    width: 300px;
    height: 360px;
    top: 279px;
    left: 0;

    a {
        cursor: pointer;
    }
}

.client-details--button-container{
    @extend .col-sm-6;
    @extend .pull-right;
    padding-left: 10px;
}

.client-details--add-button {
    @extend .icon-plus-square-o;
    @extend %icongen;
    color: $buttonWhiteFontColour;
    font-size: 26px;
    cursor: pointer; 
}

.client-details--remove-button {
    @extend .icon-minus-square-o;
    @extend %icongen;
    color: $buttonWhiteFontColour;
    font-size: 26px;
    cursor: pointer; 
}

.percentage {
    width: 82px;
    float: left;
}

.percentage-sign {
    margin: 0 10px;
    display: inline;
}

.dob-error {
    clear: both;
    padding: 6px 16px;
}

.has-error {
    background-color: #f5afaf;
    .help-block{
        color: #880303;
        text-align: left;
        clear: both;
        padding-left: 18px;
        padding-top: 10px;
        margin-bottom: 5px;
    }
}

// .ng-invalid-required {
//     border-color: #a94442;
//     -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
// }

