.modal {
position: fixed;
top: 0;
bottom: 0;
right: 0;
left: 0;
background-color: rgba(0,0,0,0.5);
}
.modal.modal-wide{
overflow: hidden;
}
.modal.modal-wide .modal-dialog {
width: 94%;
}
.modal-wide .modal-body {
overflow-y: auto;
}
.slideDown {
animation-name: slideDown;
-webkit-animation-name: slideDown;
animation-duration: 1s;
-webkit-animation-duration: 1s;
animation-timing-function: ease;
-webkit-animation-timing-function: ease;
visibility: visible !important;
}
.modal-header {
    background-color: #ececec;
    @include side-radius(top, 6px)
}

.modal-body   {
	.row {
		margin: 0;
	}
}

.close {
    float: right;
    font-size: 38px;
    font-weight: normal;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.modal-header {
	height: 54px;
	.close {
    	margin-top: -8px;
	}
	.modal-title {
		text-align: center;
	}
}

// *:focus {
// .modal.fade:not(.in).left .modal-dialog {
//   -webkit-transform: translate3d(-25%, 0, 0);
//   transform: translate3d(-25%, 0, 0);
// }
// .modal.fade:not(.in).right .modal-dialog {
//   -webkit-transform: translate3d(25%, 0, 0);
//   transform: translate3d(25%, 0, 0);
// }
// .modal.fade:not(.in).bottom .modal-dialog {
//   -webkit-transform: translate3d(0, 25%, 0);
//   transform: translate3d(0, 25%, 0);
// }
//     outline: none;
// }
// div.modal__background {
//     overflow: hidden;
//     position: fixed;
//     background-color: rgba(0, 0, 0, 0.4);
//     bottom: 0;
//     left: 0;
//     position: fixed;
//     right: 0;
//     top: 0;
//     z-index: 2000;
//      -webkit-overflow-scrolling: touch;
//   // When fading in the modal, animate it to slide down
//   &.fade                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        .modal-dialog {
//     @include translate(0, -25%);
//     @include transition-transform(0.3s ease-out);
//   }
//   &.in {
//     @include translate(0, 0)
//     @include transition-transform(0.3s ease-out);
//   }
// }
// .modal-open .modal {
//   overflow-x: hidden;
//   overflow-y: auto;
// }
// div.modal__background.ng-hide {
//     background-color: rgba(0, 0, 0, 0);
// }
// div.modal__background.ng-hide-add.ng-hide-add-active,
// div.modal__background.ng-hide-remove.ng-hide-remove-active {
//     transition: 250ms ease all;
// }
div.modal__background > .modal__window {
// background-color: #FFF;
// border-radius: 0;
// box-sizing: border-box;
// -moz-box-sizing: border-box;
// padding: 15px 18px;
// position: fixed;
// left: 50%;
// margin-left: -200px;
// z-index: 999;
// display: block;
// border: 2px solid #edeeee;
// text-align: center;
// display: none;
// p {
//     margin-bottom: 30px;
//     margin-top: 0;
//     line-height: 34px;
// }
}
// .fade {
//     // -webkit-transform: translate3d(25%, -25%, 0);
//     // transform: translate3d(25%, -25%, 0);
//     // transition: opacity .15s linear;
//     -webkit-transition: all 0.2s linear;
//     -moz-transition: all 0.2s linear;
//     -o-transition: all 0.2s linear;
//     transition: all 0.2s linear;
//     margin-top:0px;
// }
// div.modal__background.ng-hide > .modal__window.ng-enter {
//     top: -200px;
//     transition: 250ms ease top;
// }
// div.modal__background.ng-hide > .modal__window.ng-enter.ng-enter-active {
//     top: 200px;
// }
// .in {
//     margin-top:150px;
//     -webkit-transition: all 0.2s linear;
//     -moz-transition: all 0.2s linear;
//     -o-transition: all 0.2s linear;
//     transition: all 0.2s linear;
// }
// .modal__window.ng-hide-add {
//     -webkit-transform: translate3d(30%, -25%, 0);
//     transform: translate3d(30%, -25%, 0);
//     transition: opacity .15s linear;
//         opacity: 1;
// }