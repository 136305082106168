.progress-tracker__container--summary.progress-tracker--details--step5 {
	    height: 44px;
	    h2 {
	    	margin-top: 10px !important;
	    }
}


.secondary-headline {
	width: 528px;
	height: 22px;
	font-family: Open Sans;
	font-size: 16px;
	color: #183d71;
	font-weight: bold;
	text-decoration: none solid rgb(24, 61, 113);
}

.tab-title {
	width: 150px;
	height: 19px;
	font-family: Open Sans;
	font-size: 14px;
	color: #183d71;
	font-weight: bold;
	text-decoration: none solid rgb(24, 61, 113);
}

.assessment__summary {

		p.icon {
			margin-left: 0;
			margin-top: 25px;
		}

		p + p {
			margin-left: 25px;
		    margin-bottom: 24px;
		    margin-top: 24px;
		}

	.assessment__summary-container {

		border: 1px solid #dedcdc;

		.nav-pills {
			clear: both;
			background-color: $white;
		    li {
		    	width: 211px;
    			height: 38px;
    			background-color: $white;
    			& + li {

    				margin-left: 0;

    			}
		    	&.active a {
		    		background-color: #f7f7f7;
		    		color: #183d71;
		    		border-bottom: 2px solid #3498db;
		    		padding: 10px 15px 9px 15px;
		    	}
		    	a {
		    		color: #183d71;
	  				border-radius: 0;
	  				padding: 10px 15px 9px 15px;
	  				// border-bottom: 2px solid #dedcdc;
	  				&:hover {
	  					background-color: #f7f7f7;
	  				}
				}
			}
		} 
		
		.tab-content {
		  color: #183d71;
		  background-color: $white;
		  padding : 0;
		  border-top: 2px solid #dedcdc;
		  min-height: 668px;

		  .tab-pane {
			margin: 10px;
		  }
		}

	}

}