.summary__client-details {
	width: 536px;
	height: 175px;
	background-color: #ffffff;
	padding: 20px;
	margin: 10px 0;
	clear:both;
	&.childrens-cover {
		width: 536px;
		height: 210px;
	}
	&.sum-assured {
		width: 538px;
		height: 210px;
	}
	table {
		width: 100%;
	}
	&:hover {
		background-color: #f2f8fc;
	}
	.col-1 {
		width: 50px;
	}
	.col-2 {
		width: 172px;
	}
	tr {
		height: 26px;
		td {
			&.last-col {
				width: 26px;
			}
			.icon {
				margin-left: 0;
			}

			.icon-edit {
				color: #183d71;
			}
		}
	}
	.section-title {
	    font-weight: bold;
	}
}