.progress-tracker__container--policy-bar {
    height: 56px;
    background-color: #fff;
    border-top: 1px solid #dcdedf;
    border-bottom: 1px solid #dcdedf;
    .progress-tracker__policy-wrapper {
        width: 1170px;
        padding: 0;
        margin: 5px auto;
        div:first-child {
            float: left;
        }
        div {
            float: right;
        }
    }
    .selected-policy {
        width: 476px;
        height: 44px;
        border: 1px solid #dedcdc;
        border-radius: 3px;
        background-color: #f2f8fc;
        line-height: 42px;
        vertical-align: middle;
        text-align: left;
        border: 1px solid #dcdedf;
        border-radius: 2px;
        padding-left: 45px;
        font-family: Open Sans;
        font-size: 14px;
        color: #5e5e5e;
        ul {
            margin: 0;
            padding: 0;
            list-style-type: bullet;
            padding-left: 0;
                li {
                    text-transform: capitalize;
                    display: inline-block;
                    font-family: Open Sans;
                    font-size: 14px;
                    color: #5e5e5e;
                    padding-right: 10px;
                    margin-left: 20px;
                    line-height: 0;
                    &:before {
                        content:"";
                        display: list-item;
                    }
                    &:first-child {
                        list-style-type: none;
                        margin-left: 0;
                    }
                }
        }
        &.left {
            float: left;
        }
        &.right {
            float: right;
        }

    }

}

.policy-compare-circle {
    // width: 18px;
    // height: 18px;
    // border-radius: 50%;
    // color: $white;
    // font-family: Open Sans;
    // font-size: 12px;
    // font-weight: bold;
    // padding: 9px 5px;
    // margin: -10px -30px;
    margin: 0;
    padding: 0;
    height: 50px;
    width: 50px;
    overflow: hidden;
    float: left;
    font-size: 24px;
}

.policy-compare-circle--historic {
    color: #eeab57;
}

.policy-compare-circle--current {
    color: #3498db;
}

.policy-selected-badge {
    margin: -12px 0 0 -20px;
}