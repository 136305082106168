@charset "UTF-8";

.switch-toggle a, .switch-light span span {
  display: none;
}

/* We can't test for a specific feature,
 * so we only target browsers with support for media queries.
 */
@media only screen {
 /* Checkbox
*/
  .switch-light {
    position: relative;
    display: block;
   /* simulate default browser focus outlines on the switch,
  * when the inputs are focused.
  */
    &::after {
      clear: both;
      content: "";
      display: table;
    }
    * {
      box-sizing: border-box;
      &:before, &:after {
        box-sizing: border-box;
      }
    }
    a {
      display: block;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
    }
    label, > span {
     /* breathing room for bootstrap/foundation classes.
    */
      line-height: 2em;
      vertical-align: middle;
    }
    input:focus {
      ~ span a, + label {
        outline-width: 2px;
        outline-style: solid;
        outline-color: Highlight;
       /* Chrome/Opera gets its native focus styles.
      */
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 0) {
  .switch-light input:focus {
    ~ span a, + label {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
    }
  }
}

@media only screen {
 /* don't hide the input from screen-readers and keyboard access
*/
  .switch-light {
    input {
      position: absolute;
      opacity: 0;
      z-index: 3;
      &:checked ~ span a {
        right: 0%;
      }
    }
    strong {
      font-weight: inherit;
    }
    > span {
      position: relative;
      overflow: hidden;
      display: block;
      min-height: 2em;
      border-radius: 4px;
      border: 1px solid #ccc;
     /* overwrite 3rd party classes padding
    * eg. bootstrap .well
    */
      padding: 0;
      text-align: left;
    }
    span span {
      position: relative;
      z-index: 2;
      display: block;
      float: left;
      width: 50%;
      text-align: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    a {
      position: absolute;
      right: 50%;
      top: 0;
      z-index: 1;
      display: block;
      width: 50%;
      height: 100%;
      padding: 0;
    }
  }
 /* inherit from label
*/
 /* Radio Switch
*/
  .switch-toggle {
    position: relative;
    display: block;
   /* simulate default browser focus outlines on the switch,
  * when the inputs are focused.
  */
  /* For callout panels in foundation
  */
    padding: 3px !important;
   /* 2 items
  */
   /* 3 items
  */
   /* 4 items
  */
   /* 5 items
  */
   /* 6 items
  */
    &::after {
      clear: both;
      content: "";
      display: table;
    }
    * {
      box-sizing: border-box;
      &:before, &:after {
        box-sizing: border-box;
      }
    }
    a {
      display: block;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
    }
    label, > span {
     /* breathing room for bootstrap/foundation classes.
    */
      line-height: 2em;
      vertical-align: middle;
    }
    input:focus {
      ~ span a, + label {
        outline-width: 2px;
        outline-style: solid;
        outline-color: Highlight;
       /* Chrome/Opera gets its native focus styles.
      */
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 0) {
  .switch-toggle input:focus {
    ~ span a, + label {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
    }
  }
}

@media only screen {
  .switch-toggle {
    input {
      position: absolute;
      left: 0;
      opacity: 0;
      + label {
        position: relative;
        z-index: 2;
        display: block;
        float: left;
        padding: 0 0.5em;
        margin: 0;
        text-align: center;
      }
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      z-index: 1;
      width: 10px;
      height: 100%;
    }
    label:nth-child(2) {
      &:nth-last-child(4) {
        width: 50%;
        ~ {
          label, a {
            width: 50%;
            box-sizing:border-box;
          }
          input:checked:nth-child(3) + label ~ a {
            left: 50%;
            @include side-radius(left, 0);
            @include side-radius(right, 6px);
          }
        }
      }
      &:nth-last-child(6) {
        width: 33.33%;
        ~ {
          label, a {
            width: 33.33%;
          }
          input:checked {
            &:nth-child(3) + label ~ a {
              left: 33.33%;
            }
            &:nth-child(5) + label ~ a {
              left: 66.66%;
            }
          }
        }
      }
      &:nth-last-child(8) {
        width: 25%;
        ~ {
          label, a {
            width: 25%;
          }
          input:checked {
            &:nth-child(3) + label ~ a {
              left: 25%;
            }
            &:nth-child(5) + label ~ a {
              left: 50%;
            }
            &:nth-child(7) + label ~ a {
              left: 75%;
            }
          }
        }
      }
      &:nth-last-child(10) {
        width: 20%;
        ~ {
          label, a {
            width: 20%;
          }
          input:checked {
            &:nth-child(3) + label ~ a {
              left: 20%;
            }
            &:nth-child(5) + label ~ a {
              left: 40%;
            }
            &:nth-child(7) + label ~ a {
              left: 60%;
            }
            &:nth-child(9) + label ~ a {
              left: 80%;
            }
          }
        }
      }
      &:nth-last-child(12) {
        width: 16.6%;
        ~ {
          label, a {
            width: 16.6%;
          }
          input:checked {
            &:nth-child(3) + label ~ a {
              left: 16.6%;
            }
            &:nth-child(5) + label ~ a {
              left: 33.2%;
            }
            &:nth-child(7) + label ~ a {
              left: 49.8%;
            }
            &:nth-child(9) + label ~ a {
              left: 66.4%;
            }
            &:nth-child(11) + label ~ a {
              left: 83%;
            }
          }
        }
      }
    }
    &.switch-candy {
      background-color: #fff;
      border-radius: 7px;
      border: 1px solid #ccc;
      // &:hover {
      //   background-color: #f28f44;
      // }

    }
  }
 /* Candy Theme
* Based on the "Sort Switches / Toggles (PSD)" by Ormal Clarck
* http://www.premiumpixels.com/freebies/sort-switches-toggles-psd/
*/
  .switch-light.switch-candy {
    > span {
      background-color: #fff;
    }
    span span, input:checked ~ span span:first-child {
      color: #000;
      font-weight: normal;
      text-align: center;
      @include side-radius(right, 4px);
      outline: none !important;
    }
  }
  .switch-toggle.switch-candy label {
    color: #000;
    font-weight: normal;
    text-align: center;
    outline: none !important;
 //   background-color: #ccc;

  }
  .switch-light.switch-candy input {
    ~ span span:first-child, &:checked ~ span span:nth-child(2) {
      color: #fff;
    }
  }
  .switch-candy {
    input:checked + label {
      color: #fff;    
  //  background-color: $cie-menu-blue;
  }
    a {
      @include side-radius(left, 4px);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      outline: none !important;
      // background-color: $cie-menu-blue;
      background-color: #3498db;
//      background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.2), transparent);
//      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), transparent);
    }
  }
  .switch-candy-blue a {
    background-color: #38a3d4;
  }
  .switch-candy-yellow a {
    background-color: #f5e560;
  }
  /* iOS Theme
*/
  .switch-ios {
    &.switch-light {
      span span {
        color: #888b92;
      }
      a {
        left: 0;
        top: 0;
        width: 2em;
        height: 2em;
        background-color: #fff;
        border-radius: 100%;
        border: 0.25em solid #D8D9DB;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
      }
      > span {
        display: block;
        width: 100%;
        height: 2em;
        background-color: #D8D9DB;
        border-radius: 1.75em;
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
        span {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          opacity: 0;
          line-height: 1.875em;
          vertical-align: middle;
          -webkit-transition: all 0.2s ease-out;
          -moz-transition: all 0.2s ease-out;
          transition: all 0.2s ease-out;
          &:first-of-type {
            opacity: 1;
            padding-left: 1.875em;
          }
          &:last-of-type {
            padding-right: 1.875em;
          }
        }
      }
      input:checked ~ span {
        a {
          left: 100%;
          border-color: #4BD865;
          margin-left: -2em;
        }
        border-color: #4BD865;
        box-shadow: inset 0 0 0 30px #4BD865;
        span {
          &:first-of-type {
            opacity: 0;
          }
          &:last-of-type {
            opacity: 1;
            color: #fff;
          }
        }
      }
    }
    &.switch-toggle {
      background-color: #D8D9DB;
      border-radius: 30px;
      box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
      a {
        background-color: #4BD865;
        border: 0.125em solid #D8D9DB;
        border-radius: 1.75em;
        -webkit-transition: all 0.12s ease-out;
        -moz-transition: all 0.12s ease-out;
        transition: all 0.12s ease-out;
      }
      label {
        height: 2.4em;
        color: #888b92;
        line-height: 2.4em;
        vertical-align: middle;
      }
    }
    input:checked + label {
      color: #3e4043;
    }
  }
 /* Holo Theme
*/
  .switch-toggle.switch-holo, .switch-light.switch-holo > span {
    background-color: #464747;
    border-radius: 1px;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
    color: #fff;
    text-transform: uppercase;
  }
  .switch-holo {
    label {
      color: #fff;
      font-size: 85%;
      line-height: 2.15625em;
    }
    > span span {
      opacity: 0;
      -webkit-transition: all 0.1s;
      -moz-transition: all 0.1s;
      transition: all 0.1s;
      &:first-of-type {
        opacity: 1;
      }
      font-size: 85%;
      line-height: 2.15625em;
    }
    a {
      background-color: #666;
      border-radius: 1px;
      box-shadow: inset rgba(255, 255, 255, 0.2) 0 1px 0, inset rgba(0, 0, 0, 0.3) 0 -1px 0;
    }
    &.switch-light input:checked ~ span {
      a {
        background-color: #0E88B1;
      }
      span {
        &:first-of-type {
          opacity: 0;
        }
        &:last-of-type {
          opacity: 1;
        }
      }
    }
  }
  /* Selected ON switch-light
*/
 /* Material Theme
*/
 /* switch-light
*/
  .switch-light.switch-material {
    a {
      top: -0.1875em;
      width: 1.75em;
      height: 1.75em;
      border-radius: 50%;
      background: #fafafa;
      box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.14), 0 0.1875em 0.125em -0.125em rgba(0, 0, 0, 0.2), 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.12);
      -webkit-transition: right 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      -moz-transition: right 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      transition: right 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }
    overflow: visible;
  }
  .switch-material.switch-light {
    &::after {
      clear: both;
      content: "";
      display: table;
    }
    > span {
      overflow: visible;
      position: relative;
      top: 0.1875em;
      width: 3.25em;
      height: 1.5em;
      min-height: auto;
      border-radius: 1em;
      background: rgba(0, 0, 0, 0.26);
    }
    span span {
      position: absolute;
      clip: rect(0 0 0 0);
    }
    input:checked ~ span {
      a {
        right: 0;
        background: #3f51b5;
        box-shadow: 0 0.1875em 0.25em 0 rgba(0, 0, 0, 0.14), 0 0.1875em 0.1875em -0.125em rgba(0, 0, 0, 0.2), 0 0.0625em 0.375em 0 rgba(0, 0, 0, 0.12);
      }
      background: rgba(63, 81, 181, 0.5);
    }
  }
 /* switch-toggle
*/
  .switch-toggle.switch-material {
    overflow: visible;
    &::after {
      clear: both;
      content: "";
      display: table;
    }
    a {
      top: 48%;
      width: 0.375em !important;
      height: 0.375em;
      margin-left: 0.25em;
      background: #3f51b5;
      border-radius: 100%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: -webkit-transform 0.4s ease-in;
      -moz-transition: -moz-transform 0.4s ease-in;
      transition: transform 0.4s ease-in;
    }
    label {
      color: rgba(0, 0, 0, 0.54);
      font-size: 1em;
      &:before {
        content: '';
        position: absolute;
        top: 48%;
        left: 0;
        display: block;
        width: 0.875em;
        height: 0.875em;
        border-radius: 100%;
        border: 0.125em solid rgba(0, 0, 0, 0.54);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
    input:checked + label:before {
      border-color: #3f51b5;
    }
  }
 /* ripple
*/
  .switch-light.switch-material > span {
    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      display: block;
      width: 4em;
      height: 4em;
      border-radius: 100%;
      background: #3f51b5;
      opacity: .4;
      margin-left: -1.25em;
      margin-top: -1.25em;
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
      -webkit-transition: opacity 0.4s ease-in;
      -moz-transition: opacity 0.4s ease-in;
      transition: opacity 0.4s ease-in;
    }
  }
  .switch-toggle.switch-material label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: block;
    width: 4em;
    height: 4em;
    border-radius: 100%;
    background: #3f51b5;
    opacity: .4;
    margin-left: -1.25em;
    margin-top: -1.25em;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: opacity 0.4s ease-in;
    -moz-transition: opacity 0.4s ease-in;
    transition: opacity 0.4s ease-in;
  }
  .switch-light.switch-material > span:after {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -1.25em;
  }
  .switch-toggle.switch-material label:after {
    width: 3.25em;
    height: 3.25em;
    margin-top: -0.75em;
  }
  @-webkit-keyframes materialRipple {
    0% {
      -webkit-transform: scale(0);
    }

    20% {
      -webkit-transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1);
    }
  }

  @-moz-keyframes materialRipple {
    0% {
      -moz-transform: scale(0);
    }

    20% {
      -moz-transform: scale(1);
    }

    100% {
      opacity: 0;
      -moz-transform: scale(1);
    }
  }

  @keyframes materialRipple {
    0% {
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
    }

    20% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }

  .switch-material.switch-light input {
    &:not(:checked) ~ span:after, &:checked ~ span:before {
      -webkit-animation: materialRipple 0.4s ease-in;
      -moz-animation: materialRipple 0.4s ease-in;
      animation: materialRipple 0.4s ease-in;
    }
  }
  .switch-toggle.switch-material input:checked + label:after {
    -webkit-animation: materialRipple 0.4s ease-in;
    -moz-animation: materialRipple 0.4s ease-in;
    animation: materialRipple 0.4s ease-in;
  }

/* trick to prevent the default checked ripple animation from showing
* when the page loads.
* the ripples are hidden by default, and shown only when the input is focused.
*/

  .switch-light.switch-material.switch-light input ~ span {
    &:before, &:after {
      visibility: hidden;
    }
  }
  .switch-material.switch-toggle input + label:after {
    visibility: hidden;
  }
  .switch-light.switch-material.switch-light input:focus {
    &:checked ~ span:before, &:not(:checked) ~ span:after {
      visibility: visible;
    }
  }
  .switch-material.switch-toggle input:focus:checked + label:after {
    visibility: visible;
  }
}

/* Bugfix for older Webkit, including mobile Webkit. Adapted from
* http://css-tricks.com/webkit-sibling-bug/
*/

@media only screen and (-webkit-max-device-pixel-ratio: 2) and (max-device-width: 80em) {
  .switch-light, .switch-toggle {
    -webkit-animation: webkitSiblingBugfix infinite 1s;
  }
}

@-webkit-keyframes webkitSiblingBugfix {
  from {
    -webkit-transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

/* Custom */

.toggle-switch--active.active  {
    background: #f27922;
    opacity: 1;
    transition: background 0.3s linear;
}

.toggle-switch--default.active {
    // background-color: $cie-menu-blue;
    background-color: #3498db;
    opacity: 1;
    transition: background 0.3s linear; 
}



/*# sourceMappingURL=toggle-switch.css.map */