.badge {
    padding: 4px 8px;
    background-color: $cie-blue;
    border-radius: 12px;
    margin-left: 4px;
    vertical-align: top;
}

.helper-dialog-info {
	margin-left: -30px !important;
    margin-right: 10px;
    margin-top: -2px;
    color: $curious-blue !important;
}