.assessment {
	@extend .row;
}

.assessment__form, .assessment__container {
	display: table;
	@extend .col-md-12;
    padding-left: 0;
    padding-right: 0;
    width: 1170px;
    margin: 0 auto;
    float: inherit;
}

.assessment__container--left {
    float: none;
    display: table-cell;
	@extend .col-md-8;
    background-color: #fff;
    padding: 0 20px 0 20px;
}

.assessment__container--right {
	float: none;
    display: table-cell;
	@extend .col-md-4;
}

.assessment__container {
    // float: left;

    /* Firefox */
    // display:-moz-box;
    // -moz-box-pack:center;
    // -moz-box-align:center;

    // /* Safari and Chrome */
    // display:-webkit-box;
    // -webkit-box-pack:center;
    // -webkit-box-align:center;

    // /* W3C */
    // display:box;
    // box-pack:center;
    // box-align:center;
    // @extend .col-md-12; 
}

.assessment__content {
    float: left;
    @extend .col-md-12;
    max-width: 1170px; 
    padding-left: 0;
    padding-right: 0;
}

.assessment__headline {
    margin: 30px 0 0 0;
    h1 {
    	font-size: 34px;
    	color: #474747;
    }
    p {
    	font-size: 18px;
    	line-height: 30px;
    	margin-top: 10px;
    	color: #438bca;
    	font-weight: 500;
    }
}

.next-button {
    margin: 30px 0;
    padding-left: 0;
    padding-right: 0;
}

.compared-policies-container {
    width: 1170px;
    margin: 0 auto;
    padding: 0;
    margin: 5px auto;
    .compared-policy-wrapper:first-child {
        float: left;
    }
    .compared-policy-wrapper {
        float: right;
    }
}

// .compared-policy {
//     float: left;
//     padding: 18px;
//     width: 534px;
//     height: 100px;
//     border: 1px solid #dedcdc;
//     border-radius: 3px;
//     background-color: #f2f8fc;
//     ul {
//         list-style-type: none;
//         li {
//                 text-align: center;
//         }
//     }
// }

.compared-policy-wrapper {
    padding: 11px 18px 18px 13px;
    // width: 534px;
    width: 580px;
    height: 100px;
    border: 1px solid #dedcdc;
    // border-radius: 3px;
    background-color: #f2f8fc;
    line-height: 22px;
    vertical-align: middle;
    text-align: left;
    border: 1px solid #dcdedf;
    border-radius: 2px;
    font-family: Open Sans;
    font-size: 14px;
    color: #5e5e5e;
    .compared-policy--badge {
        float: left;
        .compared-policy--letter{
            margin-top: -10px;
        }
    }
    .compared-policy--info {
        // padding-left: 58px;
        padding-left: 124px;
        float: left;
        ul {
            margin: 0;
            padding: 0;
            padding-left: 0;
            list-style-type: none;
                li {
                    text-transform: capitalize;
                    text-align: center;
                    display: block;
                    font-family: Open Sans;
                    font-size: 14px;
                    color: #183d71;
                    padding-right: 10px;
                    &:before {
                        content:"";
                        display: list-item;
                    }
                    &:first-child {
                        list-style-type: none;
                        margin-left: 0;
                    }
                    .policy-compare-circle {
                        line-height: 0;
                    }
                }
        }
        &.left {
            float: left;
        }
        &.right {
            float: right;
        }
    }
    .compared-policy--logo-container {
        width: 120px;
        height: 60px;
        float: right;
        .compared-policy--logo {
            max-width: 120px;
            max-height: 60px;
            margin: 0 auto;
            display: block;
        }
    }

}

