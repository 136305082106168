.uib-datepicker-popup {
    .btn-default {
        border: none;
        text-shadow: initial;
        box-shadow: none;
    }
    li:focus {
        outline: none !important;
    }
    .btn-default.disabled,
    .btn-default.disabled.active,
    .btn-default.disabled.focus,
    .btn-default.disabled:active,
    .btn-default.disabled:focus,
    .btn-default.disabled:hover,
    .btn-default[disabled],
    .btn-default[disabled].active,
    .btn-default[disabled].focus,
    .btn-default[disabled]:active,
    .btn-default[disabled]:focus,
    .btn-default[disabled]:hover,
    fieldset[disabled] .btn-default,
    fieldset[disabled] .btn-default.active,
    fieldset[disabled] .btn-default.focus,
    fieldset[disabled] .btn-default:active,
    fieldset[disabled] .btn-default:focus,
    fieldset[disabled] .btn-default:hover {
        background-color: #fff;
        outline: none !important;
    }
    .btn-info.uib-datepicker-current {
        background-color: $cie-menu-blue;
        display: none;
    }
    .text-info {
        color: #FFF;
    }
    .btn-success {
        color: #fff;
        background-color: $cie-menu-blue;
        border-color: $cie-menu-blue;
        border-radius: 3px !important;
        &:hover {
            color: #000;
        }
        &:active {
            color: #fff;
        }
    }
    .btn-danger {
        color: #fff;
        background-color: $cie-menu-blue;
        border-color: $cie-menu-blue;
        border-radius: 3px !important;
        &:hover {
            color: #000;
        }
        &:active {
            color: #fff;
        }
    }
    .btn-info {
        color: #fff;
        background-color: $cie-menu-blue;
        border-color: $cie-menu-blue;
        border-radius: 3px !important;
        &:hover {
            color: #000;
        }
        &:active {
            color: #fff;
        }
    }
}

.uib-datepicker-popup.dropdown-menu:focus {
    outline: 0;
}

*:focus {
    outline: none !important;
}

* {
    outline: none;
}
