.test-compare {
    background: #fff;
}

.policy-compare__table--summary {
    tr > td {
        border-top: none !important;
    }
}

/* Specify styling for tooltip contents */
.tooltip.customClass .tooltip-inner {
  color: #000000;
  background-color: #ffffff;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
/* Hide arrow */
.tooltip.customClass .tooltip-arrow {
  display: none;
}

.wording {
    max-width: 50%;
    white-space: normal !important;
}

p.bg-info {
    padding: 10px; 
}

/* Conditions Covered Title Tab */

.conditions-compared__title {
    @extend .col-xs-2;
    float: left;
    width: 100%;
    border-radius: 5px 12px 0px 0px;
    background-color: rgb(235, 235, 235);
    width: 213px;
    text-align: left;
    padding-left: 12px;
    &.active {
        background-color: $white;
    }

    h2 {
        font-size: 15px;
        color:#183d71;
        font-weight: bold;
        padding-left: 0;
        padding-bottom: 4px;
        margin-top: 10px;
    }
}

.conditions-compared-main-row .nav-tabs > li {
    float: left;
    margin-bottom: -1px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.conditions-compared__select-menu {
    @extend .col-xs-4;
    float: left;
    text-align: right;
    // width: 872px;
}

.select-menu-wrapper, .title {
    margin: 20px 0;
}

.gold {
 // color: #C98910; 
 color: #e6c200;
}

.silver {
  // color: #A8A8A8;
  color: #c6c6c6;
}

.bronze {
  // color: #965A38;
  color: #cd7f32;
}

.gold-background {
 background-color: #e6c200;
}

.silver-background {
  background-color: #c6c6c6;
}

.bronze-background {
  background-color: #cd7f32;
}

.progress-tracker__container--policy-compare {
    @extend .progress-tracker__container--details;
    height: 172px;
}

.enhanced-payments {
    small {
        color: #9a9a9a !important;
        font-weight: 500;
    }
    th, td {
        padding-top: 0;
        color: #9a9a9a !important;
        font-weight: 500;
    }
}

.summary-score-breakdown {
    background-color: $white;
    float: left;
    width: 100%;
}

.summary-score-breakdown--default {
    // height: 146px;
    // height: 154px;
    height: 158px;
    width: 100%;
    border-bottom: none;
    // border-bottom-color: #ececec;
    .table {
        width: 1170px;
        margin: 10px auto;
    }
}

.summary-score-breakdown--expanded {
    height: 240px;
    width: 100%;
    margin: 0 auto;
    border-bottom: none;
    .table {
        width: 1170px;
        margin: 0 auto;
        td, th {
            border-top: none;
            text-align: center;
        }
    }
}

.cta-full-breakdown-wrapper {
    width: 210px;
    margin: 10px auto;
    .icon {
        vertical-align: text-top;
        color: #147ec2;
        line-height: 24px;
        font-size: 24px;
    }
}

.cta-full-breakdown {
    font-family: Open Sans;
    font-size: 14px;
    color: #147ec2;
    text-decoration: none solid rgb(20, 126, 194);
    text-align: center;

}

.full-breakdown-cta {
    height: 33px;
    width: 100%;
    border-top: 1px solid #dedcdc;
    margin-top: 10px;
}

.full-breakdown-link {
    text-align: center;
    float: left;
        margin: 2px 6px;
}

.icon-angle-down {
    color: #147ec2;
    font-weight: normal;
    margin-right: 3px;
    -webkit-transition:all 300ms ease-in 0s;
    -moz-transition: all 300ms ease-in 0s;
    -o-transition: all 300ms ease-in 0s;
    transition: all 300ms ease-in 0s;
    &.active {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

.condition-group-heading {
    a {
        font-family: Open Sans;
        font-size: 13px;
        color: #422f06;
        font-weight: 600;
    }
}

.conditions-data-row { 

        > td {
            padding: 10px 8px 4px 8px !important; 
        }  

        .headings {
            padding-left: 12px;
        }

        .condition-title {
            margin-top: 4px;
        }
        
        .icon-angle-right {
            font-size: 24px;
            font-weight: normal;
            margin-right: 3px;
            -webkit-transition:all 300ms ease-in 0s;
            -moz-transition: all 300ms ease-in 0s;
            -o-transition: all 300ms ease-in 0s;
            transition: all 300ms ease-in 0s;
        &.active {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
            color: #8c8c8c;
        }
    }
}

.score-wrapper {
    width: 120px;
    height: 40px;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    text-align: center;
    padding-top: 10px;
    color: #183d71;
    b {
        font-size: 16px;
        color: #147ec2;
        font-weight: bold;
    }
}

.difference-wrapper {
    width: 240px;
    height: 41px;
    border: 1px solid #dedcdc;
    border-radius: 3px;
    background-color: #f2f8fc;
    p {
        color: #183d71;
        font-weight: bold;
        text-decoration: none solid rgb(24, 61, 113);
        margin: 0 auto;
        text-align: center;
        padding: 10px;
        span {
            color: #10730a;
            font-weight: bold;
            text-decoration: none solid rgb(16, 115, 10);
            margin: 0;
            padding: 0;
        }
    }
}

.summary-score-breakdown--default {
    overflow: hidden;
    .table {
    // Cells
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            border-top: none !important;
            padding: 4px 0;
          }
        }
      }
    }
}

$score-border: #eeeeee;

.custom-border-box--top-left-right {
    border-top: 1px solid $score-border !important;
    border-left: 1px solid $score-border;
    border-right: 1px solid $score-border;
}

.custom-border-box--left-right {
    border-left: 1px solid $score-border;
    border-right: 1px solid $score-border;
}

.custom-border-box--bottom-left-right {
    border-bottom: 1px solid $score-border;
    border-left: 1px solid $score-border;
    border-right: 1px solid $score-border; 
}

th.row-heading {
    font-size: 14px;
    color: #183d71;
    font-weight: bold;
    text-align: left !important;
}

th.row-second-heading {
    font-size: 14px;
    color: #183d71;
    font-weight: normal;
    text-align: left !important;
}

.score-headings th {
    font-size: 14px;
    color: #183d71;
    font-weight: bold;   
}

.conditions-compared-wrapper {
    border-top: 2px solid;
    border-top-color: #ececec;
}

.positive-integer {
    color: #147ec2;
}

.negative-integer {
    color: #d55351;
}

.positive-total-score {
    color: #10730a;
}

.summary-score-breakdown--expanded .table td {
  color: #147ec2;
  font-weight: normal;
  &.total-score-highlight {
    color: #183d71;
    font-weight: bold;
    font-size: 16px;
    &.positive-integer {
      color: #10730a;
    }
    &.negative-integer {
      color: #d55351;
    }
  }
}

.admin-score {
    clear: both;
    margin: 2px 0;
    font-weight: 700;
}

.conditions-compared-row {
    @extend .col-xs-12;

    float: initial !important;
    max-width: 1170px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    background: #fff;
    // border-right: 1px solid #ddd;
    // border-left: 1px solid #ddd;
    border-right: none;
    border-left: none;
    &:first-child {
        border-top: none;
    }
    // &:last-child {
    //     border-bottom: 1px solid #ddd;
    // }
}

.conditions-compared-main-row {
    @extend .conditions-compared-row;
    background: none;
    border-right: none;
    border-left: none; 
    margin-top: 40px;  
}

/* Condition Groups */

.conditions-compared-row__title {
        border-top: 1px solid #ddd;
}

.conditions-compared-group--wrapper {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    &:last-child {
        border-bottom: 1px solid #ddd;
    }
}

.conditions-group-border {
    border-top: 1px solid #ddd;
}

.conditions-compared-group-row {
    @extend .conditions-compared-row; 
    .headings {
        padding-left: 36px;
    } 
    .conditions-compared-group__heading-wrapper {   
        .condition-group-icon {
            // float: left;
            // margin: 12px 20px;
            // width: 15px;
            // height: 15px;
            // border-radius: 2px;
            // padding: 2px 4px;
            // font-size: 24px;
            // color: white;
            // margin: 9px 12px !important;
            // &.glyphicon-minus {
            //     padding: 2px 3px !important;
            // }
    //             margin: 0;
    // margin-right: 10px;
    // padding: 13px;
    // height: 50px;
    // width: 50px;
    // color: #162a36 !important;
    // overflow: hidden;
    // float: left;
    // font-size: 14px;
    //     clear: both;
        float: left;
        margin-left: 6px;
        margin-top: 12px;
        font-size: 18px;

//     margin: 0;
//     margin-right: 10px;
//     padding: 13px;
//     height: 50px;
//     width: 50px;
//     color: #162a36 !important;
//     overflow: hidden;
//     float: left;
//     font-size: 24px;
// }
        }
        p {
            font-family: Open Sans;
            font-size: 16px;
            color: #5e5e5e;
            margin: 0;  
            margin-left: 40px;
            padding: 8px 0;
        }
        // div {
        //     float: left;
        // }
    }

}

.scarlet {
    color: #ff3800 !important;
}

.yellow-orange {
    color: #ffb03b !important;
}

.sulu {
    color: #bdf271 !important;
}

.turquoise{
    color: #29d9c2 !important;
}

.cornflower-blue {
    color: #844ff7 !important;
}

.curious-blue {
    color: #3599db !important;
}

.amaranth {
    color: #f2385a !important;
}

.jaffa {
    color: #f28f44 !important;
}

.malachite {
    color: #0baf0f !important;
}



// .conditions-compared-wrapper .conditions-compared-row:last-child {
//         border-bottom: 1px solid #ddd;
// }

.glyphicon {
    color: #3498db;
    margin: 0 2px !important;
}



.conditions-data-row {
    border-top: 1px solid #ddd;

    &.active {
            background-color: #f2f8fc !important;
            border-bottom: 1px solid #ddd;
    }
    &:hover  {
            background-color: #f2f8fc !important;
    }
    th, td {
        border-top: none !important;
        background-color: transparent !important;
    }
    .headings {
        font-family: Open Sans;
        font-size: 12px;
        color: #737373;
        font-weight: bold;
        a {
            color: #737373;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.table.condition-data-table {
    margin-bottom: 0;
}

.conditions-data-sub-row {
    @extend .conditions-data-row;
        border-top:none !important;
        .sub-row--cell {
            padding: 0 !important;
        }
    &:hover  {
        background-color: #fff !important;
    }

}

.conditions-data-sub-table {
    width: 100%;
    tr {
        background-color: #fff !important;
    }
    .condition-data__plan-info {
        padding: 8px;
        width: 50%;
        vertical-align: top;
        .condition-data__title {
            font-family: Open Sans;
            font-size: 12px;
            color: #458bc7;
            margin-bottom: 0;
        }
        .condition-data__title--payout-type {
            @extend .condition-data__title;
            color: $malachite;
        }
        p {
            font-family: Open Sans;
            font-size: 12px;
            color: #737373;
            margin: 0 10px 10px 10px;
        }
    }
}

.conditions-compared-subrow {
    @extend .conditions-compared-row;
            &:last-child {
            border-bottom:none !important;
        }
    margin-top: 14px;
    background: none !important;
    border-right: none;
    border-left: none;
    .btn-primary {
        .active {
            color: #fff !important;
            background-color: #3498db !important;
            border-color: #adadad !important;
            text-shadow: none;
            box-shadow: none;           
        }
    }

    .btn-default{
        &:hover, &.active, &.focus:hover {
            color: #fff;
            background-color: #3498db;
            border-color: #adadad;
            text-shadow: none;
        }

        &:active, &:focus {
            color: #333;
            background-color: #FFF;
            border-color: #adadad;
            box-shadow: none;
        }
    }
}

.no-border {
    border-bottom: none !important;
}

.sub-row-details-wrapper {
    display: none;
    padding: 0 30px;
    &.show-content {
        display: block;
    }
}

.conditions-compared__adult-child-toggle {
    @extend .col-xs-4;
    position: absolute;
    right: 0;
    text-align: right;
    width: 180px;
    padding: 0;
}

.conditions-compared__adult-child-headline {
    float: left;
    text-align: right;
    width: 275px;
    p {
        margin: 0;
        line-height: 30px;
        text-align: right;
        font-family: Open Sans;
        font-size: 13px;
        color: #5e5e5e;
    }  
}

#panels-button {
    border: none;
    width: 232px;
    height: 29px;
    text-align: left;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    border-radius: 0;
    font-family: Open Sans;
    font-size: 13px;
    color: #737373;
    .icon-angle-down {
        float: right;
        padding: 0 4px;
        color: #5e5e5e;
    }
}

.conditions-compared__select-menu {
    .dropdown-menu {
        width: 234px;
        font-family: Open Sans;
        font-size: 13px;
        color: #737373;
        text-decoration: none solid rgb(115, 115, 115);
    }
}

.btn-primary.active {
        color: #fff !important;
        background-color: #3498db !important;
        border-color: #adadad !important;
        text-shadow: none;
        box-shadow: none;        
}

.conditions-compared__adult-child-toggle {
    .toggle-switch--active.active {
        background: #3498db;
    }
}

.tab-nav-wrapper {
    border: 1px solid #dedede;
    padding: 14px;
    margin-bottom: 15px;
}

.tabs-panels {
    margin-top: 15px;
}

.tab-panel {
    font-family: Open Sans;
    font-size: 12px;
    color: #737373;
    font-weight: normal;
    text-decoration: none;
}

.tab-nav-link {
    width: 92px;
    height: 18px;
    font-family: Open Sans;
    font-size: 12px !important;
    color: #458bc7 !important;
    font-weight: normal !important;
    text-decoration: none solid rgb(69, 139, 199);
}

.tab-nav-wrapper ul.tab-nav {
    padding-left: 0;
}

.additional-info-title {
font-family: Open Sans;
font-size: 12px;
color: #458bc7;
font-weight: normal;
text-decoration: none solid rgb(69, 139, 199);   
}

.push__right {
    margin-right: 20px;
}

.tabbed-content {
  display: none;
  min-height: 100px;
  padding-top: 10px;
}

.tabbed-content.active {
  display: block;
}

.condition-information__icon, .condition-information__headline {
    float: left;
}

.condition-information__headline {
    margin-left: 6px;
    margin-top: 21px;
}

// Tabs SCSS

.conditions-content {
  display: none;
}

.conditions-tabs input {
  display: none;
}

.conditions-tabs label:hover {
  color: #888;
  cursor: pointer;
}

.conditions-tabs input:checked + label {
  color: #555;
  border: 1px solid #ddd;
  border-top: 2px solid orange;
  border-bottom: 1px solid #fff;
}

label.conditions-compared__title {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.nav-tabs > li.active > a {
    background-color: #fff;
    border: none;
}

.nav-tabs > li.conditions-compared__title > a {
    border: none;
}

.conditions-compared-main-row .nav-tabs {
    border-bottom: none;
}

#tab1:checked ~ #content1,
#tab2:checked ~ #content2,
#tab3:checked ~ #content3,
#tab4:checked ~ #content4 {
  display: block;
}

//
.conditions-compared__filters-wrapper {
    position: absolute;
    width: 50%;
    top: 42px;
    right: 0;
    z-index: 999;
}
