.progress-tracker {
    padding-bottom: 0;
    background-color: #f5f8fa;
    @extend .col-md-12;
    padding: 0;
}

.progress-tracker__container--stepwizard, .progress-tracker__container--details, .progress-tracker__container--policy-bar {
    @extend .col-md-12;
    padding: 0;
}

.progress-tracker__container--stepwizard {
    height: 44px;
    background-color: #284e97;
    .progress-tracker--stepwizard {
        width: 1170px;
        padding: 2px 0;
        @include desktop {
            padding: 0;
        }
        margin: 0 auto;
        ul {
            margin-top: 12px;
            margin-bottom: 10px;
            padding: 0;
            li {
                display: inline-block;
                padding-right: 22px;
                color: $not-completed;
                span {
                    font-family: "Open Sans";
                    font-size: 13px;
                    font-weight: 600;
                    vertical-align: top;
                }
            }
        }
    }
}

.progress-tracker__container--details {
    height: 62px;
    background-color: #99e2ff;
    .progress-tracker--details {
        width: 1170px;
        height: 62px;
        padding: 4px;
        margin: 0 auto;
        h2 {
            font-family: "Open Sans";
            color: #183D71;
            font-size: 16px;
            font-weight: bold;
            margin-top: 12px;
            margin-bottom: 4px;         
        }
        .progress-tracker--client-summary {
            margin-left: 2px;
            float: left;
            h2 {
                margin-top: 8px;
                margin-bottom: 4px; 
            }        
            ul {
                list-style-type: bullet;
                padding-left: 0;
                li {
                    text-transform: capitalize;
                    display: inline-block;
                    color: #183D71;
                    padding-right: 10px;
                    margin-left: 20px;
                    line-height: 0;
                    &:before {
                        content:"";
                        display: list-item;
                    }
                    &:first-child {
                        list-style-type: none;
                        margin-left: 0;
                    }
                    a {
                        color: #183D71;
                        &:hover, &:focus {
                            color: #183D71;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .progress-tracker--client-details {
            ul {
                list-style-type: none;
                padding-left: 12px;
                li {
                    color: #183d71;
                    font-family: Open Sans;
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: capitalize;
                    &.space {
                        padding-bottom: 10px;
                    }
                    &:last-child {
                        padding-bottom: 10px;
                    }
                }
            }
            .overlay-links {
                float: left;
                width: 100%;
                border-top: 1px solid $white;
                padding: 8px 12px;
                span:first-child {
                    float: left;
                }
                span:last-child {
                    float: right;
                }
            }
        }
    }
}

.circle {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: $not-completed;
    display: inline-block;
    margin-right: 10px;
    float: left;
}

.circle.active {
    background: $white;
    + span {
        color: $white;
    }
}

.progress-tracker--client-details {
    float: left;
    display: block;
    color: black;
    z-index: 999;
    width: 305px;
    position: absolute;
    top: 0px;
    margin-left: -10px;
    background-color: #ccf0ff;
}

.fadein,
.fadeout {
  -webkit-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s;
  -moz-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s;
  -o-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s;
  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s;
}

.fadein.ng-hide-remove,
.fadeout.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  display: block !important;
}

.fadeout.ng-hide-add,
.fadein.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
  display: block !important;
}

.top-next-button {
    margin: 10px auto;
}