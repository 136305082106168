@import '../../components/bootstrap-sass/assets/stylesheets/bootstrap',
'../../components/angular-bootstrap-toggle-switch/style/bootstrap3/angular-toggle-switch-bootstrap-3.scss',
'../../components/ng-tabs/ngTabs.css',
'global/vars',
'global/mixins',
'global/elements',
'global/links',
'global/decorators',
'global/animation',
'global/icons',
'components/toggle-switch',
'components/loading_spinner',
'../../modules/sticky-els/sticky-els',
'../../modules/progress-tracker/progress-tracker',
'../../modules/datepicker/datepicker',
'../../modules/assessment/assessment',
'../../modules/assessment/client-details/client-details',
'../../modules/assessment/policy-select/policy-select',
'../../modules/assessment/policy-select/selected-policies',
'../../modules/assessment/policy-compare/policy-compare',
'../../modules/assessment/summary/summary',
'../../modules/assessment/summary/client-details-summary',
'../../modules/header/header',
'../../modules/footer/footer',
'../../modules/helper-dialog/helper-dialog',
'../../modules/modal/providers';