.footer {
    margin: 0;
    clear: both;
    width: 100%;
    bottom: 0;
}

.footer-support {
    background-color: $footer-support;
}

.footer-links {
    background-color: $footer-links;
}

.footer-support {
    height: 50px;
    z-index: 999;
}

.footer-links {
    height: 150px;
}

.footer-support__container, .footer-links__container {
    @extend .container;
}

.footer-support__row, .footer-links__row {
    @extend .row;
}

.ad-placement-footer-leaderboard {
    margin: 20px;
    position: relative;
    width: 728px;
    height: 90px;
}